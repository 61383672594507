#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

.font__midashi-go-mb31-pr6n, #top .top__logo-title, #top .top__logo-sneaker, #floatingfooter .container .memorialset__amount, #floatingfooter .container .memorialset__text, #floatingfooter .container .button__text--reserve {
  font-family: Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.font__gothic-bbb-pr6n, #cart-sneakers .purchese__taxfree, #modal .content__bottom, #modal .content .item__sizes, #modal .content .item__tax, #modal .content .item__size {
  font-family: Gothic Medium BBB, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.font__futo-go-b101-pr6n, #cart-sneakers .purchese__container, #modal .content .item__price, #modal .content .item__button, #modal .content .item__cart-text {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif;
  font-style: normal;
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  padding-bottom: 4rem;
}

#top::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: url("https://common.lp.koyamachuya.com/moon-walker-sneakers.lp.koyamachuya.com/images/top-pc.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 735px) {
  #top::before {
    background-image: url("https://common.lp.koyamachuya.com/moon-walker-sneakers.lp.koyamachuya.com/images/top-sp.jpg");
  }
}

#top .Index-page-content {
  padding: 0;
}

#top .sqs-layout > .sqs-row {
  margin: 0;
}

#top .sqs-block {
  padding: 0 !important;
}

#top .top__wrapper {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
}

#top .top__wrapper img {
  display: block;
}

#top .top__logo {
  position: absolute;
  top: 9vh;
  left: 3.5vw;
  color: #51A3D1;
}

@media screen and (max-width: 735px) {
  #top .top__logo {
    top: 3vh;
  }
}

#top .top__logo-image {
  display: block;
  width: 15vw;
}

@media screen and (max-width: 735px) {
  #top .top__logo-image {
    width: 24vw;
  }
}

#top .top__logo-title {
  margin: 0;
  margin-top: 2.5vh;
  font-size: 1.40625rem;
}

#top .top__logo-sneaker {
  margin: 0;
  margin-top: 1vh;
  font-size: 1.25rem;
}

#top .top__logo-sneaker--small {
  font-size: 0.9375rem;
}

#top .top__title {
  position: absolute;
  bottom: 9vh;
  left: 3.5vw;
  width: 41vw;
  margin: 0;
}

@media screen and (max-width: 735px) {
  #top .top__title {
    right: 0;
    bottom: 18.5vh;
    left: 0;
    width: 65vw;
    margin: auto;
  }
}

#top .top__limited {
  position: absolute;
  top: 0;
  right: 0;
  width: 20vw;
}

@media screen and (max-width: 735px) {
  #top .top__limited {
    width: 30vw;
  }
}

@media screen and (max-width: 735px) {
  #intro .Index-page-content {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

#point .Index-page-content {
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
}

#point .sqs-layout > .sqs-row {
  margin: 0;
}

#point .sqs-block {
  padding: 0 !important;
}

#point h2 {
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
}

#cart-sneakers p {
  margin: 0;
}

#cart-sneakers .sqs-col-1:nth-child(3) .sqs-block {
  display: none;
  padding: 0 !important;
}

#cart-sneakers .purchese__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#cart-sneakers .purchese__left {
  margin-right: 2.1875rem;
}

#cart-sneakers .purchese__price {
  font-size: 1.25rem;
  font-weight: bold;
}

#cart-sneakers .purchese__taxfree {
  font-size: 0.75rem;
}

#cart-sneakers .purchese__button {
  width: 12.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #C1C1C1;
  padding: 0.84375rem 0;
  border: none;
  border-radius: 0.15625rem;
  font-weight: bold;
}

@media screen and (max-width: 735px) {
  #cart-sneakers .purchese__button {
    width: 52vw;
  }
}

#cart-sneakers .cart__icon {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
}

#floatingfooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

#floatingfooter .Index-page-content {
  padding: 0;
}

#floatingfooter .sqs-layout > .sqs-row {
  margin: 0;
}

#floatingfooter .sqs-block {
  padding: 0 !important;
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  width: 100vw;
  height: 4rem;
  height: constant(64pxenv(safe-area-inset-bottom));
  height: calc(64px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #F0ECEC;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floatingfooter * {
  line-height: 1;
}

#floatingfooter .page-content {
  padding: 0;
}

#floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container {
    max-width: 20rem;
  }
}

#floatingfooter .container .memorialset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #51A3D1;
}

#floatingfooter .container .memorialset__amount {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__amount {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#floatingfooter .container .memorialset__text {
  margin: 0;
  margin-top: 0.40625rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .memorialset__text {
    margin-top: 0.4375rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

#floatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.78125rem 1.4375rem;
  background-color: #C1C1C1;
  border-radius: 0.15625rem;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

#floatingfooter .container .button__icon {
  width: 1.3125rem;
  height: 1.3125rem;
}

#floatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #ffffff;
}

#floatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#modal .Index-page-content {
  padding: 0;
}

#modal .sqs-layout > .sqs-row {
  margin: 0;
}

#modal .sqs-block {
  padding: 0 !important;
}

#modal {
  line-height: 1;
}

#modal .page-content {
  padding: 0;
}

#modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.53);
  transition: background-color 300ms 10ms;
}

#modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#modal .modal__content {
  max-width: 52vw;
  width: 100%;
  height: auto;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.375rem;
  transform: translateY(100vh);
  transition: transform 300ms;
  overflow: visible;
}

@media screen and (max-width: 735px) {
  #modal .modal__content {
    max-width: 90vw;
  }
}

#modal .modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.5rem 0.6875rem 0.6875rem 0.8125rem;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1;
  background-color: #E8E5E5;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(0.9375rem, -1rem);
}

@media screen and (max-width: 735px) {
  #modal .modal__close {
    transform: translate(0.625rem, -0.6875rem);
  }
}

#modal .content__bottom {
  padding: 2rem;
  font-size: 12px;
  font-size: 0.75rem;
}

@media screen and (max-width: 735px) {
  #modal .content__bottom {
    padding: 1.5rem;
  }
}

#modal .content__bottom .item__info {
  line-height: 1.2rem;
}

#modal .content__bottom .info__link {
  color: #51A3D1;
  border-bottom: 0.0625rem solid #51A3D1;
}

#modal .content__wrapper {
  display: flex;
  width: 100%;
  padding: 2rem 2rem 0rem 2rem;
}

@media screen and (max-width: 735px) {
  #modal .content__wrapper {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
  }
}

#modal .content__left {
  width: 50%;
}

@media screen and (max-width: 735px) {
  #modal .content__left {
    width: 35%;
  }
}

#modal .content__right {
  width: 50%;
  height: 50vh;
  overflow-y: scroll;
}

@media screen and (max-width: 735px) {
  #modal .content__right {
    width: 65%;
    height: 45vh;
  }
}

#modal .content .item__image {
  display: block;
  width: 80%;
}

#modal .content .item__sizes {
  margin: 0;
  margin-top: 0.625rem;
  font-size: 12px;
  font-size: 0.75rem;
}

@media screen and (max-width: 735px) {
  #modal .content .item__sizes {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

#modal .content .item__sizes .sp-only {
  display: none;
}

@media screen and (max-width: 735px) {
  #modal .content .item__sizes .sp-only {
    display: block;
  }
}

#modal .content .item__price {
  margin: 0;
  margin-top: 0.9375rem;
  font-size: 23.5px;
  font-size: 1.46875rem;
  font-weight: bold;
}

@media screen and (max-width: 735px) {
  #modal .content .item__price {
    font-size: 16px;
    font-size: 1rem;
  }
}

#modal .content .item__tax {
  margin: 0;
  margin-top: 0.75rem;
  font-size: 14.5px;
  font-size: 0.90625rem;
}

@media screen and (max-width: 735px) {
  #modal .content .item__tax {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

#modal .content .item__list {
  margin: 0;
  padding: 0;
}

#modal .content .item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9375rem 0;
  border-bottom: 0.0625rem solid #E8E5E5;
}

#modal .content .item__content:first-child {
  padding-top: 0;
}

#modal .content .item__content:last-child {
  padding-bottom: 2rem;
  border-bottom: none;
}

@media screen and (max-width: 735px) {
  #modal .content .item__content:last-child {
    padding-bottom: 1.5rem;
  }
}

#modal .content .item__size {
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
}

#modal .content .item__form {
  width: 66%;
}

#modal .content .item__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: #ffffff;
  background-color: #C1C1C1;
  border: none;
  border-radius: 0.125rem;
}

#modal .content .item__cart-icon {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
}

#modal .content .item__cart-text {
  margin: 0;
  margin-left: 0.28125rem;
  font-size: 12px;
  font-size: 0.75rem;
}

@media screen and (max-width: 735px) {
  #modal .sqs-layout .sqs-row .sqs-block:first-child {
    padding-top: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block:last-child {
    padding-bottom: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block {
    padding: 0;
  }
}
