#floatingfooter {
  display: flex;
  justify-content: center;
  align-items: center;

  .Index-page-content {
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  * {
    line-height: 1;
  }

  width: 100vw;
  height: pxToRem(64);
  height: constant(64px + env(safe-area-inset-bottom));
  height: calc(64px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #F0ECEC;
  opacity: 0;
  z-index: 999;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .page-content {
    padding: 0;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: pxToRem(375);
    margin: 0 auto;
    @include mq(sm) {
      max-width: pxToRem(320);
    }
  }
}

#floatingfooter {
  .container {
    .memorialset {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #51A3D1;
      &__amount {
        margin: 0;
        @extend .font__midashi-go-mb31-pr6n;
        @include fontSizePxToRem(14);
        @include mq(sm) {
          @include fontSizePxToRem(12);
        }
      }
      &__text {
        margin: 0;
        margin-top: pxToRem(6.5);
        @extend .font__midashi-go-mb31-pr6n;
        @include fontSizePxToRem(10);
        @include mq() {
          margin-top: pxToRem(7);
        }
        @include mq(sm) {
          @include fontSizePxToRem(10);
        }
      }
    }
    .button {
      display: flex;
      align-items: center;
      padding: pxToRem(12.5) pxToRem(23);
      background-color: #C1C1C1;
      border-radius: pxToRem(2.5);
      border: none;
      cursor: pointer;
      @include mq(sm) {
        padding-right: pxToRem(15);
        padding-left: pxToRem(15);
      }
      &__icon {
        width: pxToRem(21);
        height: pxToRem(21);
      }
      &__text {
        margin-left: pxToRem(8);
        color: #ffffff;
        &--reserve {
          margin: 0;
          @extend .font__midashi-go-mb31-pr6n;
          @include fontSizePxToRem(19);
          @include mq(sm) {
            @include fontSizePxToRem(16);
          }
        }
      }
    }
  }
}