#point {
  .Index-page-content {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }

  h2 {
    padding-right: pxToRem(17);
    padding-left: pxToRem(17);
  }
}