#top {
  &::before {
    content:"";
    display:block;
    position:fixed;
    top:0;
    left:0;
    z-index:-1;
    width:100vw;
    height:100vh;
    background-image: url("https://common.lp.koyamachuya.com/moon-walker-sneakers.lp.koyamachuya.com/images/top-pc.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include mq() {
      background-image: url("https://common.lp.koyamachuya.com/moon-walker-sneakers.lp.koyamachuya.com/images/top-sp.jpg");
    }
  }

  .Index-page-content {
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }

  .top {
    &__wrapper {
      display: block;
      position: relative;
      width: 100vw;
      height: 100vh;

      img {
        display: block;
      }
    }

    &__logo {
      position: absolute;
      top: 9vh;
      left: 3.5vw;
      color: #51A3D1;

      @include mq() {
        top: 3vh;
      }
    }

    &__logo-image {
      display: block;
      width: 15vw;

      @include mq() {
        width: 24vw;
      }
    }

    &__logo-title {
      margin: 0;
      margin-top: 2.5vh;
      @extend .font__midashi-go-mb31-pr6n;
      font-size: pxToRem(22.5);
    }

    &__logo-sneaker {
      margin: 0;
      margin-top: 1vh;
      @extend .font__midashi-go-mb31-pr6n;
      font-size: pxToRem(20);

      &--small {
        font-size: pxToRem(15);
      }
    }

    &__title {
      position: absolute;
      bottom: 9vh;
      left: 3.5vw;
      width: 41vw;
      margin: 0;

      @include mq() {
        right: 0;
        bottom: 18.5vh;
        left: 0;
        width: 65vw;
        margin: auto;
      }
    }

    &__limited {
      position: absolute;
      top: 0;
      right: 0;
      width: 20vw;

      @include mq() {
        width: 30vw;
      }
    }
  }
}