#modal {
  .Index-page-content {
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }
}

#modal {
  line-height: 1;

  .page-content {
    padding: 0;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateY(100vh);
    transition: transform 10ms 300ms;
    z-index: 1000;
    &.open {
      transform: translateY(0);
      transition: transform 10ms;
      .modal__backdrop {
        background-color: rgba(0, 0, 0, 0.53);
        transition: background-color 300ms 10ms;
      }
      .modal__content {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
  }
}

#modal {
  .modal {
    &__backdrop {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms;
    }
  }
}

#modal {
  .modal {
    &__content {
      max-width: 52vw;
      width: 100%;
      height: auto;
      color: #000000;
      background-color: rgba(255, 255, 255, 0.95);
      border-radius: pxToRem(6);
      transform: translateY(100vh);
      transition: transform 300ms;
      overflow: visible;

      @include mq() {
        max-width: 90vw;
      }
    }
  }
}

#modal {
  .modal {
    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: pxToRem(32);
      height: pxToRem(32);
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: pxToRem(8) pxToRem(11) pxToRem(11) pxToRem(13);
      @include fontSizePxToRem(21);
      line-height: 1;
      background-color: #E8E5E5;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(pxToRem(15), pxToRem(-16));

      @include  mq() {
        transform: translate(pxToRem(10), pxToRem(-11));
      }
    }
  }
}

#modal {
  .content {
    &__bottom {
      padding: pxToRem(32);
      @include fontSizePxToRem(12);
      @extend .font__gothic-bbb-pr6n;

      @include mq() {
        padding: pxToRem(24);
      }

      .item {
        &__info {
          line-height: 1.2rem;
        }
      }

      .info {
        &__link {
          color: #51A3D1;
          border-bottom: pxToRem(1) solid #51A3D1;
        }
      }
    }
  }
}

#modal {
  .content {
    &__wrapper {
      display: flex;
      width: 100%;
      padding: pxToRem(32) pxToRem(32) pxToRem(0) pxToRem(32);

      @include mq() {
        padding: pxToRem(24) pxToRem(24) pxToRem(0) pxToRem(24);
      }
    }

    &__left {
      width: 50%;

      @include mq() {
        width: 35%;
      }
    }

    &__right {
      width: 50%;
      height: 50vh;
      overflow-y: scroll;

      @include mq() {
        width: 65%;
        height: 45vh;
      }
    }
  }
}

#modal {
  .content {
    .item {
      &__image {
        display: block;
        width: 80%;
      }

      &__sizes {
        margin: 0;
        margin-top: pxToRem(10);
        @include fontSizePxToRem(12);
        @extend .font__gothic-bbb-pr6n;

        @include mq() {
          @include fontSizePxToRem(10);
        }

        .sp-only {
          display: none;

          @include mq() {
            display: block;
          }
        }
      }

      &__price {
        margin: 0;
        margin-top: pxToRem(15);
        @include fontSizePxToRem(23.5);
        @extend .font__futo-go-b101-pr6n;
        font-weight: bold;

        @include mq() {
          @include fontSizePxToRem(16);
        }
      }

      &__tax {
        margin: 0;
        margin-top: pxToRem(12);
        @include fontSizePxToRem(14.5);
        @extend .font__gothic-bbb-pr6n;

        @include mq() {
          @include fontSizePxToRem(10);
        }
      }
    }
  }
}

#modal {
  .content {
    .item {
      &__list {
        margin: 0;
        padding: 0;
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: pxToRem(15) 0;
        border-bottom: pxToRem(1) solid #E8E5E5;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: pxToRem(32);
          border-bottom: none;

          @include mq() {
            padding-bottom: pxToRem(24);
          }
        }
      }

      &__size {
        margin: 0;
        @include fontSizePxToRem(12);
        @extend .font__gothic-bbb-pr6n;
      }

      &__form {
        width: 66%;
      }

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-top: pxToRem(10);
        padding-bottom: pxToRem(10);
        @extend .font__futo-go-b101-pr6n;
        color: #ffffff;
        background-color: #C1C1C1;
        border: none;
        border-radius: pxToRem(2);
      }

      &__cart-icon {
        display: block;
        width: pxToRem(14);
        height: pxToRem(14);
      }

      &__cart-text {
        margin: 0;
        margin-left: pxToRem(4.5);
        @include fontSizePxToRem(12);
        @extend .font__futo-go-b101-pr6n;
      }
    }
  }
}

#modal {
  @include mq() {
    .sqs-layout .sqs-row .sqs-block:first-child {
      padding-top: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block:last-child {
      padding-bottom: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block {
      padding: 0;
    }
  }
}