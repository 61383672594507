#cart-sneakers {
  p {
    margin: 0;
  }

  .sqs-col-1:nth-child(3) {
    .sqs-block {
      display: none;
      padding: 0 !important;
    }
  }

  .purchese {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .font__futo-go-b101-pr6n;
    }

    &__left {
      margin-right: pxToRem(35);
    }

    &__price {
      font-size: pxToRem(20);
      font-weight: bold;
    }

    &__taxfree {
      @extend .font__gothic-bbb-pr6n;
      font-size: pxToRem(12);
    }

    &__button {
      width: pxToRem(197);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #C1C1C1;
      padding: pxToRem(13.5) 0;
      border: none;
      border-radius: pxToRem(2.5);
      font-weight: bold;

      @include mq() {
        width: 52vw;
      }
    }
  }

  .cart__icon {
    display: block;
    width: pxToRem(16);
    height: pxToRem(16);
    margin-right: pxToRem(12);
  }
}